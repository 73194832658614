import type { ZoneFeatures } from 'src/config/types';

import type { ResultsState } from './types';

export const getRoleIdFromResults = (results: ResultsState) => {
  const roleIds = results?.results?.jobs
    ?.map((job) => !job.isPremium && job.roleId)
    .filter((id) => id);

  return roleIds?.[0] || undefined;
};

export const getPageSize = (
  zoneFeatures: ZoneFeatures,
  isV5Search: boolean,
) => {
  const pageSize = zoneFeatures?.SEARCH_PAGE_SIZE || 20;

  // Featured ads are included by default in V5 so we need to explicitly request the correct amount of jobs here
  // TODO Once we cutover fully to V5, we should properly update all page size constants

  if (isV5Search) {
    return pageSize + 2;
  }

  return pageSize;
};
